<template>
  <v-dialog
    value="true"
    max-width="900"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>{{ $t('sell') }} {{ this.item.title }} {{ $t('online') }}</v-card-title>
      <v-card-subtitle>{{ $t('aPreviewOfWhatThisItemLooksLikeOnline') }}</v-card-subtitle>
      <v-container
        fluid
        class="py-0 px-6"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-row
              dense
            >
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="channel"
                  :items="activeChannels"
                  item-value="uuid"
                  item-text="label"
                  label="Channel"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="language"
                  :items="languages"
                  item-value="key"
                  item-text="name"
                  :label="$t('language')"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-switch
                  :label="$t('darkMode')"
                  v-model="darkMode"
                  dense
                  persistent-hint
                  :hint="$t('darkModeHint')"
                  class="ma-0"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-switch
                  :label="$t('hideImage')"
                  v-model="hideImage"
                  dense
                  persistent-hint
                  :hint="$t('hideImageHint')"
                  class="ma-0"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-row
                  align="center"
                >
                  <v-col
                    class="py-0 grow pr-0"
                    cols="auto"
                  >
                    <v-text-field
                      hide-details
                      :label="$t('link')"
                      dense
                      outlined
                      v-model="url"
                      disabled
                    />
                  </v-col>
                  <v-col
                    class="py-0 px-2"
                    cols="auto"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                          icon
                          :disabled="!url"
                          @click="copyUrlToClipboard(url)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('copyLink') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                          icon
                          :disabled="!url"
                          @click="showItemSettings = !showItemSettings"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-cog</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('settings') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                          icon
                          :disabled="!url"
                          @click="previewUrl = url"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-arrow-right-drop-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('preview') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="pb-3"
                v-show="showItemSettings"
              >
                <v-card
                  elevation="3"
                >
                  <v-container fluid>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <span
                          style="font-weight: 600; font-size: 18px;"
                        >
                          {{ $t('itemSettings') }}
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-switch
                          :label="$t('showAdditionalItemsUpsell')"
                          v-model="showUpsell"
                          dense
                          persistent-hint
                          :hint="$t('showAdditionalItemsUpsellHint')"
                          class="ma-0"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-switch
                          v-model="allowQuantitySelection"
                          class="ma-0"
                          dense
                          :label="$t('allowQuantitySelection')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <ColorSelect
                          :label="$t('buttonColor')"
                          dense
                          outlined
                          hide-details
                          clearable
                          v-model="primaryColor"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                      >
                        <v-select
                          :label="$t('minimumQuantity')"
                          dense
                          outlined
                          v-model="miq"
                          :items="quantities"
                          clearable
                          persistent-hint
                          :hint="$t('minimumQuantityHint')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-select
                          :label="$t('maximumQuantity')"
                          dense
                          outlined
                          clearable
                          v-model="maq"
                          :items="quantities"
                          persistent-hint
                          :hint="$t('maximumQuantityHint')"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-btn
                  :href="url"
                  target="_blank"
                  block
                  large
                  color="primary"
                  :disabled="url === null"
                >
                  {{ $t('openLink') }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                class="pb-3"
                v-if="item.type === 'event'"
              >
                <v-row
                  align="center"
                >
                  <v-col
                    class="py-0 grow pr-0"
                    cols="auto"
                  >
                    <v-text-field
                      hide-details
                      :label="$t('schedule')"
                      dense
                      outlined
                      disabled
                      v-model="scheduleUrl"
                    />
                  </v-col>
                  <v-col
                    class="py-0 px-2"
                    cols="auto"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :disabled="!scheduleUrl"
                          @click="copyUrlToClipboard(scheduleUrl)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('copyLink') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                          icon
                          :disabled="!scheduleUrl"
                          @click="showScheduleSettings = !showScheduleSettings"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-cog</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('settings') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                          icon
                          :disabled="!scheduleUrl"
                          @click="updatePreviewUrl(scheduleUrl)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-arrow-right-drop-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('preview') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="pb-3"
                v-show="showScheduleSettings"
              >
                <v-card
                  elevation="3"
                >
                  <v-container fluid>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <span
                          style="font-weight: 600; font-size: 18px;"
                        >
                          {{ $t('scheduleSettings') }}
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-select
                          :label="$t('location')"
                          v-model="locationUUID"
                          :items="locations"
                          item-text="label"
                          item-value="uuid"
                          hide-details
                          outlined
                          dense
                          clearable
                        />
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-switch
                          :label="$t('showImages')"
                          v-model="showImages"
                          class="ma-0"
                          hide-details
                        />
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-switch
                          :label="$t('minimalFooter')"
                          v-model="enableMinimalFooter"
                          class="ma-0"
                          hide-details
                        />
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-switch
                          :label="$t('hideItemPrice')"
                          v-model="hideItemPrice"
                          class="ma-0"
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                v-if="item.type === 'event'"
              >
                <v-btn
                  :href="scheduleUrl"
                  target="_blank"
                  block
                  large
                  color="primary"
                  :disabled="scheduleUrl === null"
                >
                  {{ $t('openSchedule') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <iframe
                :title="$t('item')"
                width="100%"
                height="600px"
                :src="previewUrl"
                style="border: 1px solid #ccc;"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ColorSelect from "@/components/common/ColorSelect"

export default {
    name: 'SellOnlineDialog',
  components: {
      ColorSelect
  },
  props: {
      item: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
        close() {
            this.$emit('closed')
        },
        async copyUrlToClipboard(url) {
          if(typeof navigator !== 'undefined' && navigator !== null) {
            if(typeof navigator.clipboard !== 'undefined' && navigator.clipboard !== null) {
              await navigator.clipboard.writeText(url)
            }
          }
      },
      updatePreviewUrl(url) {
        this.previewUrl = url
        if(this.previewUrl) {
          if(this.previewUrl.includes('item.salescloud.is')) {
            this.showItemSettings = true
          }
          if(this.previewUrl.includes('schedule.salescloud.is')) {
            this.showScheduleSettings = true
          }
        }
      }
    },
    data: () => ({
        channel: null,
        language: 'is',
        hideImage: false,
        darkMode: false,
      primaryColor: null,
      showUpsell: true,
      showItemSettings: false,
      showScheduleSettings: false,
      miq: null,
      maq: null,
      previewUrl: null,
      locationUUID: null,
      enableMinimalFooter: false,
      showImages: true,
      hideItemPrice: false,
      allowQuantitySelection: false,
    }),
  watch: {
      url(newUrl) {
        if(typeof this.item !== 'undefined' && this.item !== null) {
          // Handling item types that are not event types
          // These will not show a Schedule link option
          if(this.item.type !== 'event') {
            this.previewUrl = newUrl
          } else {
            if(this.previewUrl && this.previewUrl.includes('item.salescloud.is')) {
              this.previewUrl = newUrl
            }
          }
        }
      },
    scheduleUrl(newUrl) {
        if(this.previewUrl && this.previewUrl.includes('schedule.salescloud.is')) {
          this.previewUrl = newUrl
        }
    }
  },
  computed: {
      locations() {
        return this.$store.state.locations.filter(l => l && l.active === true)
      },
        quantities() {
          return Array.from({ length: 101 }, (_,i) => i)
        },
        languages() {
          return this.$store.state.languages
        },
        organization() {
          return this.$store.state.organization
        },
        url() {

          if(typeof this.item === 'undefined' || this.item === null) {
            return null
          }

            if(this.channel === null) {
              return null
            }

            return 'https://item.salescloud.is/?uuid=' + this.item.uuid + '&channel=' + this.channel +
              (this.organization ? '&organization=' + this.organization.uuid : '') + '&lang=' + this.language +
              (this.hideImage ? `&hideImage=${this.hideImage}` : '') +
              (this.darkMode ? `&dark=${this.darkMode}` : '') +
              (this.primaryColor ? `&primaryColor=${this.primaryColor.replace('#', '')}` : '') +
              (this.showUpsell === false ? `&showUpsell=${this.showUpsell}` : '') +
              (this.allowQuantitySelection === true ? `&allowQuantitySelection=${this.allowQuantitySelection}` : '') +
              (this.miq > 0 ? `&miq=${this.miq}` : '') +
              (this.maq > 0 ? `&maq=${this.maq}` : '')
        },
        scheduleUrl() {

          if(this.channel === null) {
              return null
          }

          return 'https://schedule.salescloud.is/?organization=' + this.organization.uuid +
            '&item=' + this.item.uuid +
            '&channel=' + this.channel +
            '&lang=' + this.language +
            (this.darkMode ? `&dark=${this.darkMode}` : '') +
            (this.locationUUID ? `&l=${this.locationUUID}` : '') +
            (this.enableMinimalFooter ? '&mf=true' : '') +
            (this.showImages === false ? '&showImages=false' : '') +
            (this.hideItemPrice ? '&hip=true' : '')
        },
        activeChannels() {
          return this.$store.state.channels.filter(channel => channel.active)
      },
    }
}
</script>
