<template>
  <v-dialog
    max-width="500"
    v-model="value"
    @click:outside="close"
  >
    <v-card>
      <v-card
        tile
        flat
        :color="primaryColor"
        :class="textColor"
      >
        <v-card-title>
          {{ $t('addNewItemTypeTitleToLowercaseItem', [itemType.title.toLowerCase()]) }}
        </v-card-title>
      </v-card>
      <ItemForm
        :value="newItem"
        class="pa-4"
        @created="wasCreated"
      />
    </v-card>
  </v-dialog>
</template>

<script>

import ItemForm from './ItemForm'

export default {
	name: 'ItemDialog',
	components: {
		ItemForm
	},
	props: {
		value: {
			type: Boolean
		},
		itemType: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		textColor() {
			return this.$store.getters.textButtonColor(this.primaryColor)
		}
	},
	methods: {
		close() {
			this.$emit('closed')
		},
		wasCreated(item) {
			console.log(item)
		}
	},
	data() {
		return {
			newItem: {
				title: '',
				sku: '',
				barcode: '',
				type: this.itemType.namespace,
				categories: [],
				price: null,
				cost_price: null,
				sale_price: null,
				description: {
					value: ''
				},
				sorting_weight: 0,
				status: true,
				uiValues: null
			}
		}
	}
}
</script>