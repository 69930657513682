<template>
  <div>
    <SellOnlineDialog
      v-if="itemToSellOnline !== null"
      :item="itemToSellOnline"
      @closed="itemToSellOnline = null"
    />
    <ItemTypeDialog
      :key="showAddItemDialog"
      v-if="showAddItemDialog"
      @closed="itemTypeDialogClosed"
      @selectedItemType="selectItemType"
    />
    <ItemDialog
      v-if="showItemDialog"
      v-model="showItemDialog"
      :item-type="selectedItemType"
      @closed="itemDialogClosed"
    />
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedItem"
      @clearSelectedObject="selectedItem = null"
    >
      <template v-slot:list="{ item }">
        <ItemList
          v-if="item.key === 'active'"
          :items="activeItems"
          @selectItem="selectItem"
          :load-items-callback="getItems"
          :fixed-filters="activeFilters"
          :call-to-actions="callToActions"
          :actions="actions"
        />
        <ItemList
          v-if="item.key === 'inactive'"
          :items="inactiveItems"
          @selectItem="selectItem"
          :load-items-callback="getItems"
          :fixed-filters="inactiveFilters"
          :call-to-actions="callToActions"
          :actions="actions"
        />
        <ItemList
          v-if="item.key === 'all'"
          :items="allItems"
          @selectItem="selectItem"
          :fixed-filters="allItemsFilters"
          :load-items-callback="getItems"
          :call-to-actions="callToActions"
          :actions="actions"
        />
        <template v-for="(namespace) in itemNamespaces">
          <ItemList
            :key="namespace"
            v-if="item.key === namespace"
            :fixed-filters="itemTypeFilter(item.key)"
            :load-items-callback="getItems"
            :call-to-actions="callToActions"
            :actions="actions"
            @selectItem="selectItem"
          />
        </template>
      </template>
      <template slot="profile">
        <ItemProfile
          class="grey lighten-3 mb-3 overflow-y-auto"
          :item="selectedItem"
          v-if="selectedItem !== null && selectedItem !== {}"
          style="max-height: 100vh"
          v-on="$listeners"
          @sellOnline="sellOnline"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>

import ItemList from "@/components/ItemList"
import ItemTypeDialog from "@/components/ItemTypeDialog"
import ItemDialog from "@/components/ItemDialog"
import ItemProfile from "@/components/item/ItemProfile"
import SellOnlineDialog from '@/components/SellOnlineDialog'
import ExtendedView from "@/components/common/ExtendedView"

export default {
	name: 'StoreItems',
	components: {
		ItemDialog,
		ItemList,
		ItemTypeDialog,
		ItemProfile,
		SellOnlineDialog,
		ExtendedView
	},
	data() {
		return {
			showAddItemDialog: false,
			showItemDialog: false,
			selectedItemType: null,
			selectedItem: null,
			itemToSellOnline: null,
			callToActions: [
				{
					title: this.$t('addItem'),
					callback: this.addItem
				}
			],
			activeFilters: [
				{
					key: 'status',
					value: true,
					operator: 'equals',
					type: 'boolean'
				},
        {
          key: 'isDeleted',
          value: true,
          operator: 'not_equals',
          type: 'boolean'
        }
			],
			inactiveFilters: [
				{
					key: 'status',
					value: false,
					operator: 'equals',
					type: 'boolean'
				},
        {
          key: 'isDeleted',
          value: true,
          operator: 'not_equals',
          type: 'boolean'
        }
			],
      allItemsFilters: [
        {
          key: 'isDeleted',
          value: true,
          operator: 'not_equals',
          type: 'boolean'
        }
      ]
		}
	},
	methods: {
		getItems() {
			return this.$store.dispatch('getItems')
		},
		selectItem(item) {
			this.selectedItem = item
		},
		itemTypeDialogClosed() {
			this.showAddItemDialog = false
		},
		itemDialogClosed() {
			this.showItemDialog = false
		},
		selectItemType(itemType) {
			this.selectedItemType = itemType
			this.showAddItemDialog = false
			this.showItemDialog = true
		},
		addItem() {
			this.showAddItemDialog = true
		},
		exportItems() {
			this.$store.commit('updateExportObjects', this.items)
		},
    itemsInNamespace(namespace) {
			if(this.items === null) {
				return []
			}

			return this.items.filter(item => {
        if(typeof item !== 'undefined' && item !== null) {
          if(typeof item.type !== 'undefined' && item.type !== null) {
            return item.type === namespace
          }
        }
				return false
			})
		},
    itemTypeFilter(namespace) {
      return [
        {
          key: 'type',
          type: 'text',
          value: namespace,
          operator: 'equals'
        },
        {
          key: 'isDeleted',
          value: true,
          operator: 'not_equals',
          type: 'boolean'
        }
      ]
    },
		itemsInCategory: function(categoryUUID) {

			if(this.items === null) {
				return []
			}

			return this.items.filter(item => {

				const hasCategory = item.categories.find(category => category.uuid === categoryUUID)

				if(hasCategory) {
					return true
				}

				return false
			})
		},
		sellOnline(item) {
			this.itemToSellOnline = item
		}
	},
	watch: {
		items(newItems, oldItems) {
			if(newItems !== null && oldItems === null) {
				this.$store.commit('updateAppBarTabs', this.tabs)
			}
		},
		categories(newCategories, oldCategories) {
			if(newCategories !== null && oldCategories === null) {
				this.$store.commit('updateAppBarTabs', this.tabs)
			}
		}
	},
	computed: {
		actions() {
			return this.$store.getters.bulkOperationsByRoute('store/items')
		},
		showBottomSheet: {
			get() {
				return this.selectedItem !== null
			},
			set(value) {
				this.selectedItem = null
			}
		},
		query() {
			return this.$store.state.searchQuery
		},
		showItemProfile() {
			if( this.selectedItem !== null && typeof this.selectedItem !== 'undefined' && Object.keys(this.selectedItem).length !== 0) {
				return true
			}
			return false
		},
		activeItemsCount() {

			if(this.activeItems !== null) {
				return this.activeItems.length
			}

			return 0
		},
		inactiveItemsCount() {

			if(this.inactiveItems !== null) {
				return this.inactiveItems.length
			}

			return 0
		},
		allItemsCount: function() {

			if(this.items !== null && this.items !== undefined) {
				return this.items.length
			}

			return 0
		},
		items() {
			return this.$store.state.items
		},
    allItems() {
      if(Array.isArray(this.items)) {
        return this.items.filter(item => item && item.isDeleted !== true)
      }
      return null
    },
		inactiveItems() {

			if(this.items === null || this.items === undefined) {
				return null
			}

			return this.items.filter(item => {
				return !item.status
			})
		},
		activeItems() {

			if(this.items === null || this.items === undefined) {
				return null
			}

			return this.items.filter(item => {
				return item.status
			})
		},
		selectedAppBarTab() {
			return this.$store.state.selectedAppBarTab
		},
		categories() {
			return this.$store.state.categories
		},
		itemNamespaces() {
			const itemTypesOfItems = []

			for(let i in this.items) {
				const namespace = this.items[i].type
				if(!itemTypesOfItems.includes(namespace)) {
					itemTypesOfItems.push(namespace)
				}
			}

			return itemTypesOfItems
		},
		tabs() {

			const tabs = []

			tabs.push({
				title: this.$t('active'),
				key: 'active',
				badge: {
					color: 'green',
					content: this.activeItemsCount,
				}
			})

			tabs.push({
				title: this.$t('inactive'),
				key: 'inactive',
				badge: {
					color: 'red',
					content: this.inactiveItemsCount,
				}
			})

			tabs.push({
				title: this.$t('all'),
				key: 'all',
				badge: {
					color: 'blue',
					content: this.allItemsCount,
				}
			})

			if(this.item !== null) {
				for(let i in this.itemNamespaces) {
					const namespace = this.itemNamespaces[i]
          const itemsInNamespace = this.itemsInNamespace(namespace)
					tabs.push({
						title: namespace,
						key: namespace,
            badge: {
              color: 'blue lighten-2',
              content: itemsInNamespace ? itemsInNamespace.length : 0
            }
					})
				}
			}

			return tabs
		}
	},
  created() {
	  this.$store.dispatch('getItemLocationBlocks')
  },
	mounted() {
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
		this.$store.commit('updateAppBarExport', {
			title: this.$t('exportItems'),
			callback: this.exportItems
		})
	},
	destroyed() {
		this.$store.commit('updateAppBarTabs', [])
	}
}
</script>
