<template>
  <v-dialog
    max-width="500"
    v-model="dialog"
    @click:outside="closed"
  >
    <v-card>
      <v-card-title>{{ $t('Choose an item type') }}</v-card-title>
      <v-card-subtitle class="pb-0">
        {{ $t('Pick the right item type from the provided options') }}
      </v-card-subtitle>
      <v-card-text>
        <v-list :class="showMore ? 'pb-0' : ''">
          <template v-for="(itemType, index) in activeItemTypes">

            <v-list-item
              v-if="itemType"
              link
              class="px-0"
              target="_blank"
              rel="noopener noreferrer"
              @click="redirectOrOpenCreateModal(itemType.namespace)"
              :key="itemType.namespace"
            >
            
              <v-list-item-avatar>
                <v-icon color="rgb(40, 110, 250)">
                  {{ itemType.icon ? `mdi-${itemType.icon}` : 'mdi-plus' }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>

                <v-list-item-title>{{ itemType.title }}</v-list-item-title>

                <v-list-item-subtitle class="text-wrap">
                  {{ itemType.description }}
                </v-list-item-subtitle>

                <v-list-item-subtitle
                  v-if="itemType.short_description"
                  class="font-italic text-wrap"
                >
                  {{ itemType.short_description }}
                </v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>

            <v-divider
              :key="index"
              v-if="index < itemTypes.length - 1"
            />

          </template>
        </v-list>
        <v-list
          v-if="showMore"
          class="pt-0"
        >
          <template v-for="(itemType, index) in otherItemTypes">

            <v-list-item
              v-if="itemType"
              link
              class="px-0"
              target="_blank"
              rel="noopener noreferrer"
              :href="'https://admin.salescloud.is/store/add/' + itemType.namespace + '?destination=store/items'"
              :key="itemType.namespace"
            >

              <v-list-item-avatar>
                <v-icon color="rgb(40, 110, 250)">
                  {{ itemType.icon ? `mdi-${itemType.icon}` : 'mdi-plus' }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>

                <v-list-item-title>{{ itemType.title }}</v-list-item-title>

                <v-list-item-subtitle class="text-wrap">
                  {{ itemType.description }}
                </v-list-item-subtitle>

                <v-list-item-subtitle
                  v-if="itemType.short_description"
                  class="text--secondary text-wrap"
                >
                  {{ itemType.short_description }}
                </v-list-item-subtitle>

              </v-list-item-content>

            </v-list-item>

            <v-divider
              :key="index"
              v-if="index < itemTypes.length - 1"
            />
          </template>
        </v-list>
        <div
          class="text-decoration-underline"
          style="cursor:pointer;"
          @click="showMore = !showMore"
        >
          <span v-if="!showMore">Show more <v-icon>mdi-chevron-down</v-icon></span><span v-else>Show less <v-icon>mdi-chevron-up</v-icon></span>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
	name: 'ItemTypeDialog',
	data: () => ({
		dialog: true,
		activeItemTypesFilter: [ 'product', 'coupon', 'giftcard' ],
		showMore: false
	}),
	methods: {
		closed() {
			this.$emit('closed')
		},
		selectItemType(itemType) {  
			this.$emit('selectedItemType', itemType)
		},
    createItem(newItem) {
      return this.$store.dispatch('createItem', newItem).then(result => {
        if(result) {
          // Success
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success')
          })
          return true
        } else {
          // Failure
          this.$store.commit('updateActionError', {
            message: this.$t('error'), 
            subMessage: this.$t('unknownErrorPleaseTryAgainIfErrorPersistsPleaseContactSupport')
          })
          return false
        }
      }).catch(() => {
        // Failure
        this.$store.commit('updateActionError', {
          message: this.$t('error'), 
          subMessage: this.$t('unknownErrorPleaseTryAgainIfErrorPersistsPleaseContactSupport')
        })
        return false
      })
    },
    redirectOrOpenCreateModal(itemType) {

      switch(itemType) {
        case 'product':
          // open modal

          this.$store.commit('updateDataToMutate', {
            objectToMutate: {
              title: null,
              type: itemType,
              sku: null,
              categories: [],
              variation_sets: [],
              default_variations: [],
              seasonalPrices: [],
              weekdayPrices: [],
              openingHours: [],
              locationPrices: [],
              description: {
                summary: null,
                value: null,
                format: 'filtered_html'
              },
              sellOnline: true,
              sellInStore: true,
              sellInEtags: true,
              sellInLocations: [],
              isDeleted: false,
              price: {
                amount: null,
                currency_code: 'ISK',
                taxRate: null
              },
              sale_price: {
                amount: null,
                currency_code: "ISK",
                taxRate: null
              } ,
              cost_price: {
                amount: null,
                currency_code: "ISK",
                taxRate: null
              },
              imageUrl: null,
              status: true,
              exclude_discounts: false,
              accountingKey: null,
              mainCategory: null,
              payWhatYouWant: false,
              popup: false
            },
            formComponent: 'components/ItemForm',
            saveCallback: this.createItem,
            title: `${this.$t('create')} ${itemType}`,
            persistent: true
          })
          this.closed()
          break
        case 'coupon':
          // open modal

          this.$store.commit('updateDataToMutate', {
            objectToMutate: {
              title: null,
              type: itemType,
              sku: null,
              categories: [],
              seasonalPrices: [],
              weekdayPrices: [],
              openingHours: [],
              locationPrices: [],
              description: {
                summary: null,
                value: null,
                format: 'filtered_html'
              },
              sellOnline: true,
              sellInStore: true,
              sellInEtags: true,
              sellInLocations: [],
              isDeleted: false,
              price: {
                amount: null,
                currency_code: 'ISK',
                taxRate: null
              },
              imageUrl: null,
              status: true,
              accountingKey: null,
              redeemableItems: [],
              max_redemptions: 0,
              mainCategory: null
            },
            formComponent: 'components/ItemForm',
            saveCallback: this.createItem,
            title: `${this.$t('create')} ${itemType}`,
            persistent: true
          })
          this.closed()
          break
        case 'giftcard':
          this.$store.commit('updateDataToMutate', {
            objectToMutate: {
              title: null,
              type: itemType,
              sku: null,
              categories: [],
              seasonalPrices: [],
              weekdayPrices: [],
              openingHours: [],
              locationPrices: [],
              description: {
                summary: null,
                value: null,
                format: 'filtered_html'
              },
              sellOnline: true,
              sellInStore: true,
              sellInEtags: true,
              sellInLocations: [],
              isDeleted: false,
              price: {
                amount: null,
                currency_code: 'ISK',
                taxRate: null
              },
              sale_price: {
                amount: null,
                currency_code: "ISK",
                taxRate: null
              },
              imageUrl: null,
              status: true,
              validity: 24,
              accountingKey: null,
              exclude_discounts: false,
              send_to_customer: true,
              payment_instance: null,
              mainCategory: null
            },
            formComponent: 'components/ItemForm',
            saveCallback: this.createItem,
            title: `${this.$t('create')} ${itemType}`,
            persistent: true
          })
          this.closed()
          break
        default:
          window.open(`https://admin.salescloud.is/store/add/${itemType}?destination=store/items`, '_blank')
          this.closed()
          break
      }
    }
	},
	computed: {
		itemTypes() {
			return this.$store.state.itemTypes?.filter(type => type?.namespace !== 'fundraiser')
		},
		activeItemTypes() {
			return this.itemTypes?.filter(type => this.activeItemTypesFilter.includes(type?.namespace))
		},
		otherItemTypes() {
			return this.itemTypes?.filter(type => !this.activeItemTypesFilter.includes(type?.namespace))
		}
	}
}
</script>
